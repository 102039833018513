html,
body {
  overflow-x: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
}
a {
  text-decoration: none !important;
}
#root {
  display: flex;
  justify-content: center;
}
@font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/AirbnbCerealBook.woff") format("woff");
}

.main__app {
  font-family: "Airbnb Cereal App Book";
  display: flex;
  position: absolute;
  max-width: 100%;
  height: auto;
  width: 1500px;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
  align-items: center;
}

/* Navbar */

.navbar__main {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
}
.navbar__content {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__stretch {
  margin-bottom: 300px;
}
.nav__links {
  font-family: "Airbnb Cereal App Book";
  margin: 0px 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #4f4f4f;
  text-decoration: none !important;
  z-index: 1;
}
.active_link {
  color: #db4437;
  font-weight: bold;
}
.primary-btn {
  margin-right: 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  background: #4285f4;
  border-radius: 5px;
  padding: 14px 60px;
}
.primary-btn:hover {
  text-decoration: none !important;
  color: white;
}
.world-img {
  position: absolute;
  max-width: 100%;
  width: 850.48px;
  height: 496px;
  left: 25%;
  top: -61px;
}
.flex__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px;
  /* border: 1px solid black; */
  justify-content: space-between;
}
/* Navbar Ends */

/* Team  */
.page__headline {
  /* border: 1px solid red; */
  width: 500px;
  margin: 50px;
  font-size: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.page__heading {
  margin-bottom: 20px;
  font-weight: 1000;
  font-size: 45px;
  line-height: 68px;
  color: #333333;
}
.page__content {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #4f4f4f;
}
.team__container {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid green; */
}
.team__card {
  padding: 50px;
  margin-bottom: 40px;
  /* flex-basis: 25%; */
  display: flex;
  flex-direction: column;
}
.member__img {
  position: relative;
  width: 300px;
  height: 300px;
  margin-bottom: 28px;
  border-radius: 10px;
  overflow-y: hidden;
  /* align-self: center; */
  align-items: center;
  /* align-content: center; */
  /* justify-self: center; */
  /* justify-content: center; */
  display: flex;
  min-height: 300px;
}
.content__overlay {
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  bottom: 100%;
  left: 0;
  right: 0;
  background: linear-gradient(288.06deg, #444bab 7.52%, #5a65ef 166.1%);
  overflow: hidden;
  width: 100%;
  height: 0;
  z-index: 10;
  transition: 0.4s ease;
}
.overlay__content {
  margin-left: 10px;
  margin-top: 20px;
  margin-right: 2px;
  padding: 20px;
  overflow-y: scroll;
  max-height: 230px;
}
.content__overlay span {
  font-size: 17px;
  color: #ffffff;
  line-height: 166.3%;
}
.display__img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  height: auto;
}
.member__social {
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: all 0.8s ease;
}
.member__img:hover .member__social {
  opacity: 1;
}
.member__social a {
  margin-right: 10px;
}
.member__social img {
  width: 20px;
  height: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  border-radius: none !important;
}
.member__img:hover .content__overlay {
  bottom: 0;
  height: 100%;
}

.member__name {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.member__designation {
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.scrollbar {
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}
#scrollb_style::-webkit-scrollbar-track {
	background-color: #444bab;
}
#scrollb_style::-webkit-scrollbar {
	width: 6px;
	background-color: #444bab;
}
#scrollb_style::-webkit-scrollbar-thumb {
	background-color: #444bab;
}
@media (max-width: 990px) {
  .navbar__main {
    justify-content: space-between;
    background: white;
    z-index: 1000;
    height: 85px;
    width: 100%;
    margin-left: 0 !important;
    /* position: fixed; */
    top: 0px;
    box-shadow: 0px 4px 12px rgba(70, 74, 140, 0.12);
  }

  .navbar__content {
    display: none;
  }
  .world-img {
    left: 0%;
  }

  .flex__row {
    /* height: 100vh; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
    justify-content: center;
  }
  .page__headline {
    width: 100%;
    /* word-break: break-all; */
    text-align: center;
  }
  .page__headline a {
    margin: 0 auto;
  }
  .row {
    gap: 30px;
    justify-content: center;
  }
  .card {
    height: auto;
  }
}
/* Team Ends */

/* Sidebar */
.sidebar-container {
  box-shadow: 0px 4px 12px rgba(70, 74, 140, 0.12);
  position: absolute;
  height: 350px;
  width: 100%;
  background-color: white;
  color: grey;
  z-index: 1000;
  top: 80px;
  transition: all 0.5s ease;
  left: 0;
  /* box-shadow: 1px 1px 8px #414141; */
}
.sidebar-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
  margin-left: 20px;
}

.sidebar-closed {
  position: absolute;
  height: 0%;
  width: 100%;
  background-color: white;
  color: grey;
  top: 80px;
  transition: all 0.5s ease;
  left: 0;
  z-index: 1000;
}
.sidebar a {
  line-height: 56px;
  color: grey;
  font-size: 20px;
  text-decoration: none;
}

.menu-icon {
  color: grey !important;
  height: 40px !important;
  width: 40px !important;
  z-index: 1000000;
  cursor: pointer;
  position: relative;
  right: 10px;
}

.join-us-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  padding: 14px 34px;
  width: 191px;
  height: 54px;
  left: 260px;
  top: 654px;

  /* Royal Blue */

  background: #4285f4;
  box-shadow: 0px 8px 16px rgba(66, 133, 244, 0.32);
  border-radius: 5px;
}

.join-us-btn p {
  position: static;
  width: 75px;
  height: 26px;
  left: 82px;
  top: 14px;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
}

.join-us-btn img {
  position: static;
  margin-right: 10px !important;
  width: 24px;
  height: 24px;
  left: 34px;
  top: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}

.card {
  box-shadow: 0px 4px 52px rgba(70, 74, 140, 0.24);
  border-radius: 10px;
  color: white;
  padding: 30px;
  /* margin: 50px; */
  /* height: 330px; */
}

.card a {
  margin-top: auto;
}

.slack-card {
  background: linear-gradient(103.39deg, #5d1f5f 0%, #4a154b 101.05%);
  /* margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px; */
}

.discord-card {
  background: linear-gradient(102.26deg, #7289da -11.29%, #556ec8 95.67%);
  /* margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px; */
}

.telegram-card {
  background: linear-gradient(102.26deg, #20a0e1 -11.29%, #2890c6 95.67%);
  /* margin-left: 30px !important;
  margin-top: 15px;
  margin-bottom: 15px; */
}

.mail-card {
  background: linear-gradient(103.39deg, #ea4335 0%, #c5221f 101.05%);
  /* margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px; */
}

.btn-join {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 60px;
  width: 230px;
  height: 51px;
  left: 304px;
  top: 1926px;
  background: #f2f2f2;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.btn-join p {
  padding-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  font-weight: lighter !important;
}

/* Project Page */
.project__card {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(70, 74, 140, 0.1);
  border-radius: 10px !important;
}

.project__image {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.view-btn {
  margin-right: 0 !important;
  font-weight: bold;
  font-size: 18px;
  /* line-height: 23px; */
  color: #ffffff;
  background: #4285f4;
  border-radius: 5px;
  padding: 10px 50px;
  margin-top: 50px !important;
}
.view-btn:hover {
  text-decoration: none !important;
  color: white;
}

@media only screen and (max-width: 414px) {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .project__image img {
    height: 300px !important;
  }
  .project__image {
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 10px !important;
  }
  div[aria-label="animation"] {
    width: 400px;
    overflow: hidden;
    outline: none;
    max-width: 100%;
    padding: 0px !important;
  }
  .join-us-btn {
    margin: auto !important;
  }
  .page__content {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 414px) {
  .card {
    margin: 10px !important;
    height: auto;
  }
  .slack-card {
    background: linear-gradient(103.39deg, #5d1f5f 0%, #4a154b 101.05%);
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .discord-card {
    background: linear-gradient(102.26deg, #7289da -11.29%, #556ec8 95.67%);
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px !important;
  }

  .telegram-card {
    background: linear-gradient(102.26deg, #20a0e1 -11.29%, #2890c6 95.67%);
    /* margin-left: 30px !important; */
    margin-top: -35px !important;
    margin-bottom: 15px;
  }

  .mail-card {
    background: linear-gradient(103.39deg, #ea4335 0%, #c5221f 101.05%);
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mobile-para {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .lottie {
    margin-left: -20px !important;
  }
  .btn-join {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 51px;
    left: 304px;
    top: 1926px;
    background: #f2f2f2;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .row {
    grid-gap: 0px;
    gap: 0px;
  }
  .flex__row {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 770px) {
  .card {
    height: 330px;
  }
}

@media only screen and (max-width: 990px) {
  .card {
    height: auto;
  }
}

@media only screen and (max-width: 1150px) {
  .nav__links {
    margin: 0px 1vw;
  }
}
