.footer {
  background: #f3f3f3;
  margin-bottom: 0px !important;
  width: 100%;
  color: white;
  text-align: center;
}

.footer img {
  padding: 40px;
}

.icon-tray img {
  padding: 20px !important;
}

.icon-tray {
  margin-bottom: 40px;
}

.copyright {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 0px !important;
  margin: auto;
  width: 70%;
  background: #f4b400;
  border-radius: 20px 20px 0px 0px;
  padding-top: 30px;
}

@media screen and (max-width: 768px) {
  .logo{
      width: 90%;
  }
}